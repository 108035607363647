import React from "react";

const Legend = () => {
  return (
    <>
    <div className="legendContainer">
      <div className="legendSubContainer">
        <p>Légende journées :</p>
        <span>
          <div
            style={{ backgroundColor: "#9bedc0" }}
            className="legendSquare"
          ></div>
          Week end
        </span>

        <span>
          <div
            style={{ backgroundColor: "#bdfff4" }}
            className="legendSquare"
          ></div>
          Semaine
        </span>

        <span>
          <div className="legendSquare cross"></div> Indisponible
        </span>
      </div>
      <div className="legendSubContainer">
        <p>Légende saisons :</p>
        <span>
          <div
            style={{ backgroundColor: "#1a8fff" }}
            className="legendSquare"
          ></div>
          Basse Saison
        </span>

        <span>
          <div
            style={{ backgroundColor: "#fd6e15" }}
            className="legendSquare"
          ></div>
          Foire de Bourail
        </span>

        <span>
          <div
            style={{ backgroundColor: "orange" }}
            className="legendSquare"
          ></div>
          Vacances Scolaire
        </span>
      </div>
    </div>
      <p className="doubleCLickForOneNight">Double cliquer pour une seule nuit*</p>
    </>
  );
};

export default Legend;
