
import React, { useEffect, useRef, useState } from "react";
import NavBar from "../Components/Navigation";
import Prestations from "./Prestations";
import Lodge from "./Lodge";
import Hebergement from "./Hebergement";
import Restauration from "./Restauration";
import Reservation from "./Reservation";

function Index() {
  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();

  /********* Remove # href from url****************** */
  useEffect(() => {
    window.location.replace("#");
    if (typeof window.history.replaceState == "function") {
      window.history.replaceState({}, "", window.location.href.slice(0, -1));
    }
  }, []);

  
  const [dispoVisible, setDispoVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const myRef = useRef(null);
  const homePage = useRef(null);
  const myRefHebergement = useRef(null);
  const myRefRestauration = useRef(null);
  const myRefPrestation = useRef(null);
  const myRefT = useRef(null);
  const myRefHebergementT = useRef(null);
  const myRefRestaurationT = useRef(null);
  const myRefPrestationT = useRef(null);
  const myRefReservationT = useRef(null);

  const hoverEffect = () => {
    const interval = setTimeout(() => {
      myRefT.current.style.opacity = 1;
    }, 500);
    setTimeout(() => {
      myRefT.current.style.opacity = "";
      myRefHebergementT.current.style.opacity = 1;
    }, 1000);

    setTimeout(() => {
      myRefHebergementT.current.style.opacity = "";

      myRefRestaurationT.current.style.opacity = 1;
    }, 1500);

    setTimeout(() => {
      myRefRestaurationT.current.style.opacity = "";
      myRefPrestationT.current.style.opacity = 1;
    }, 2000);

    setTimeout(() => {
      myRefPrestationT.current.style.opacity = "";
      myRefReservationT.current.style.opacity = 1;
    }, 2500);
    setTimeout(() => {
      myRefReservationT.current.style.opacity = "";
    }, 3000);

    return () => clearInterval(interval);
  };


  
  useEffect(() => {
    hoverEffect();
  }, []);

  const tabs = [
    [
      <div
        className="image"
        ref={homePage}
        onClick={() => hoverEffect()}
        style={{
          backgroundImage: "url(./assets/images/uh4-min.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="titleOnglet">Accueil </div>

        <div className="text-container">
          <div className="text">
            <span
              style={{ fontFamily: "    Kaushan Script", fontSize: "1.8rem" }}
            >
              Betikure Parc Lodge
            </span>
          </div>
        </div>
      </div>,
    ],
    [
      <div
        className="image"
        ref={myRefT}
        onClick={() => setDispoVisible(false)}
        id="lodge"
        style={{
          backgroundImage: "url(./assets/images/lodgeviewfar.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <a className="deskptopOngletLink" href="#lodgeInfo">
          <div className="titleOnglet">Le Lodge</div>
          <div className="text-container">
            <div className="text">
              <h3>Le Lodge</h3>
              <p>A propos de Betikure</p>
            </div>
          </div>
        </a>

        <div className="mobileOngletLink">
          <div className="titleOnglet">Le Lodge</div>

          <div className="text-container">
            <a href="#lodgeInfo">
              <div className="arrow-container animated fadeInDown">
                <div className="arrow-2">
                  <i className="fa fa-angle-down"></i>
                </div>
                <div className="arrow-1 animated hinge infinite zoomIn"></div>
              </div>
              <div className="text">
                <h3>Le Lodge</h3>
                <p>A propos de Betikure</p>
              </div>
            </a>
          </div>
        </div>
        <div id="lodgeInfo" ref={myRef}>
          {" "}
          <Lodge />
        </div>
      </div>,
    ],
    [
      <div
        className="image"
        ref={myRefHebergementT}
        onClick={() => setDispoVisible(false)}
        id="hebergement"
        style={{
          backgroundImage: "url(./assets/images/UH3.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <a className="deskptopOngletLink" href="#hebergementInfo">
          <div className="titleOnglet">Hébergement</div>
          <div className="text-container">
            <div className="text">
              <h3>Hébergement</h3>
              <p>Villas & Suites & Chambres</p>
            </div>
          </div>
        </a>

        <div className="mobileOngletLink">
          <div className="titleOnglet">Hébergement</div>
          <div className="text-container">
            <a href="#hebergementInfo">

              <div className="arrow-container animated fadeInDown"> 
                <div className="arrow-2">
                  <i className="fa fa-angle-down"></i>
                </div>
                <div className="arrow-1 animated hinge infinite zoomIn"></div>
              </div>
              <div className="text">
                <h3>Hébergement</h3>
                <p>Villas & Suites & Chambres</p>
              </div>
            </a>
          </div>
        </div>
        <div id="hebergementInfo" ref={myRefHebergement}>
        
          <Hebergement setActiveTab={setActiveTab} />
        </div>
      </div>
    ],
    [
      <div
        className="image"
        ref={myRefRestaurationT}
        onClick={() => setDispoVisible(false)}
        id="restauration"
        style={{
          backgroundImage: "url(./assets/images/restaurant4.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <a className="deskptopOngletLink" href="#restaurationInfo">
          <div className="titleOnglet">Restauration</div>
          <div className="text-container">
            <div className="text">
              <h3>Restauration</h3>
              <p>Notre restaurant</p>
            </div>
          </div>
        </a>
        <div className="mobileOngletLink">
          <div className="titleOnglet">Restauration</div>
          <div className="text-container">
            <a href="#restaurationInfo">

            <div className="arrow-container animated fadeInDown"> 
                <div className="arrow-2">
                  <i className="fa fa-angle-down"></i>
                </div>
                <div className="arrow-1 animated hinge infinite zoomIn"></div>
              </div>

              <div className="text">
                <h3>Restauration</h3>
                <p>Notre restaurant</p>
              </div>
            </a>
          </div>
        </div>

        <div id="restaurationInfo" ref={myRefRestauration}>
          <Restauration />
        </div>
      </div>,
    ],
    [
      <div
        className="image"
        ref={myRefPrestationT}
        onClick={() => setDispoVisible(false)}
        id="prestations"
        style={{
          backgroundImage: "url(./assets/images/activités/golf.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <a className="deskptopOngletLink" href="#prestationsInfo">
          <div className="titleOnglet">Activités</div>
          <div className="text-container">
            <div className="text">
              <h3>Activités</h3>
              <p>Activités autour du lodge</p>
            </div>
          </div>
        </a>

        <div className="mobileOngletLink">
          <div className="titleOnglet">Activités</div>
          <div className="text-container">
            <a href="#prestationsInfo">

            <div className="arrow-container animated fadeInDown"> 
                <div className="arrow-2">
                  <i className="fa fa-angle-down"></i>
                </div>
                <div className="arrow-1 animated hinge infinite zoomIn"></div>
              </div>

              <div className="text">
                <h3>Activités</h3>
                <p>Activités autour du lodge</p>
              </div>
            </a>
          </div>
        </div>

        <div id="prestationsInfo" ref={myRefPrestation}>
          {" "}
          <Prestations />
        </div>
      </div>,
    ],
    [
      <div
        className="image"
        ref={myRefReservationT}
        id="reservation"
        onClick={() => setDispoVisible(true)}
        style={{
          backgroundImage: "url(./assets/images/1.jpg)",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <a className="deskptopOngletLink" href="#reservationInfo">
          <div className="titleOnglet">Réservation</div>
          <div className="text-container">
            <div className="text">
              <h3>Réservation</h3>
              <p>Effectué vos réservation en ligne</p>
            </div>
          </div>
        </a>
        <div className="mobileOngletLink">
          <div className="titleOnglet">Réservation</div>
          <div className="text-container">
            <a href="#reservationInfo">
            <div className="arrow-container animated fadeInDown"> 
                <div className="arrow-2">
                  <i className="fa fa-angle-down"></i>
                </div>
                <div className="arrow-1 animated hinge infinite zoomIn"></div>
              </div>
              <div className="text">
                <h3>Réservation</h3>
                <p>Effectué vos réservation en ligne</p>
              </div>
            </a>
          </div>
        </div>
        <div id="reservationInfo">
          {" "}
          <Reservation dispo={dispoVisible} />
        </div>
      </div>,
    ],
  ];

  const scrollToTopOffTabs = (index, ref) => {
    activeTab !== index && ref.map((ref) => {
      ref[0].ref.current.scrollTo(0, 0);
   

    })
    

  };
  return (
    <>
      <NavBar />

      <div className="tabContainer">
        {tabs.map((tab, index, ref) => {
          return (
            <div
              key={index}
              onClick={() => {
                setActiveTab(index);
                scrollToTopOffTabs(index, ref);
              }}
        
              className={`${"tabContainerChildren"}  ${
                activeTab === index ? "active" : "" 
              }`}
            >
              {tab}
            </div>
          );
        })}
      </div>
{/* 
      <script src="./assets/jquery.min.js" type="text/javascript"></script>
      <script src="./assets/bootstrap.js" type="text/javascript"></script> */}
      <script src="./script.js" type="text/javascript"></script>
    </>
  );
}

export default Index;
