import React, { useState, useRef, useEffect} from 'react'
import SlideSlicker from '../Components/SlickSlider';
import ReservationCompo from '../Components/ReservationCompo';
import ReservationChambreCompo from '../Components/ReservationChambreCompo';
import ReservationSuiteCompo from '../Components/ReservationSuiteCompo';
import CarouselReservation from '../Components/CarouselReservation';

function Hebergement({setActiveTab}) {

const refLink1 = useRef(null)
const refLink2 = useRef(null)
const refLink3 = useRef(null)
const [modal, setModal] = useState(false);
const [modalSuite, setModalSuite] = useState(false);
const [modalChambre, setModalChambre] = useState(false);
const [modalBois, setModalBois] = useState(false);
const [autrePhotos, setAutrePhotos] = useState(false)


// useEffect(() => {


// const interval = setInterval(() => {
    

//  setTimeout(() => {
//     refLink1.current.style.color = 'black'
//     refLink3.current.style.color = ''
//   }, 0);

// setTimeout(() => {
//     refLink2.current.style.color = 'black'
//     refLink1.current.style.color = ''
//   }, 2500);

// setTimeout(() => {
//     refLink3.current.style.color = 'black'
//     refLink2.current.style.color = ''
//   }, 5000);
// setTimeout(() => {
//     refLink3.current.style.color = ''

//   }, 7500);

  
// }, 17500); 
// return () => clearInterval(interval);
// }, [])


  // const [hebergement, setHebergement] = useState('')

  // const renderSwitch = () => { 
  //   switch(hebergement) {
  //     case 'villa':
  //       return <ReservationCompo setActiveTab={setActiveTab} />
  //     case 'chambre':
  //       return <ReservationChambreCompo setActiveTab={setActiveTab}/>
  //     case 'suite':
  //       return <ReservationSuiteCompo  setActiveTab={setActiveTab}/>
  //     default:
  //       return <br />;
  //   }
  // }
const goToGalery = () => {
setTimeout(() => {
  return refLink1.current.scrollIntoView({
    behavior: 'smooth',
    block: 'start'
  })
}, 200);
}


  return (
    <div className="hebergement">
      <div className="paddingTopMobile"></div>
      <h1>Suites et chambres</h1>
      <div className="hebergementContent">

        <div className="villaGrid grid">
          <h2>Villas</h2>
            <div className="imageLeftHebergement imagesHebergement"></div>
            <p>
              Betikure dispose de 6 mini villas et d'une suite étudiée pour
              faciliter l'accès aux personnes à mobilité réduite.
            </p>

      
          <div className="imageRightHebergement"></div>
          <p>Chaque mini villa permet de réserver une chambre ou une suite. Lorsqu'elles sont louées ensemble, elles communiquent par une terrasse et constituent une mini villa confortable pour 6 personnes ( 9 avec 3 lits d’appoint).</p>
          <button  onClick={() => setModal(!modal)}>Liste des équipements</button>
          {modal ? (
          <div className="overlay">
            <div className="modal">
            <button  className="buttonModal" onClick={() => setModal(false)}>
          X
        </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
            <iframe
            className="ListEquipement"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "100%",
              height: "1320px",
              overflow: "hidden",
            }}
            src="./assets/villa.html"
          ></iframe>
            <iframe
            className="ListEquipementMobile"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "100%",
              height: "700px",
              overflow: "hidden",
            }}
            src="./assets/villaMobile.html"
          ></iframe>

            </div>
            </div>
          </div>
        ) : null}
        </div>
    
        <div className="chambreGrid grid">
          <h2>Chambres</h2>
          <div className="imageChambreHebergement imagesHebergement"></div>
          <p>
            Chambre de 35m² avec terassse, prévue pour 2 personnes mais
            acceptant 1 lit supplémentaire et/ou 1 lit bébé. Salle d'eau avec
            douche à l'italienne, wc sépares.{" "}
          </p>
          <button onClick={() => setModalChambre(!modalChambre)}>Liste des équipements</button>
          {modalChambre ? (
          <div className="overlay">
            <div className="modal">
            <button className="buttonModal" onClick={() => setModalChambre(false)}>
          X
        </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
                
            <iframe
            className="ListEquipement"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "80%",
              height: "1320px",
              overflow: "hidden",
            }}
            src="./assets/chambre.html"
          ></iframe>
            <iframe
            className="ListEquipementMobile"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "100%",
              height: "700px",
              overflow: "hidden",
            }}
            src="./assets/chambreMobile.html"
          ></iframe>

            </div>
            </div>
          </div>
        ) : null}
        </div>

        <div className="suiteGrid grid">
          <h2>Suites</h2>
          <div className="imageSuiteHebergement imagesHebergement"></div>
          <p>
            {" "}
            Suite de 65 m² proposant 2 chambres (Lits double ou simple), 1 salle
            d'eau, 1wc séparé, 1 salon, 1 cuisine 1 terasse haute couverte, 1
            terasse basse avec jacuzzi privé.Une suite acceuille 4 personnes, 6 avec 2 lits d'appoint.</p>

          <button onClick={() => setModalSuite(!modalSuite)}>Liste des équipements</button>
          {modalSuite ? (
          <div className="overlay">
            <div className="modal">
            <button className="buttonModal" onClick={() => setModalSuite(false)}>
          X
        </button>
              <h4>Liste des équipements</h4>
              <br />
                 <div className="iframeModal">
            <iframe
            className="ListEquipement"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "80%",
              height: "1320px",
              overflow: "hidden",
            }}
            src="./assets/suite.html"
          ></iframe>
                    <iframe
            className="ListEquipementMobile"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "100%",
              height: "700px",
              overflow: "hidden",
            }}
            src="./assets/suiteMobile.html"
          ></iframe>
          

            </div>
            </div>
          </div>
        ) : null}
                  <div className="imageSuite2Hebergement imagesHebergement"></div>
        </div>

        <div className="boisGrid grid">
          <h2>Bois Noir</h2>
          <div className="imageBoisNoirGrid imagesHebergement"></div>
          <p>
            « Bois noir » est une suite pour 2 personnes (4 avec 2 lits
            d‘appoint). Elle propose une partie jour avec un salon avec cuisine
            et une partie nuit composée d’une grande chambre ouvrant sur le parc
            et sur une terrasse avec jacuzzi privé.{" "}
          </p>
          <div className="imageBoisNoir2Grid imagesHebergement"></div>
          <p>
            Sans être « médicalisée » cette suite est adaptée aux personnes à
            mobilité réduite (proximité piste, plein pied, dégagements
            importants, cuisine adaptée.
          </p>
          <button onClick={() => setModalBois(!modalBois)}>Liste des équipements</button>
          {modalBois ? (
          <div className="overlay">
            <div className="modal">
            <button className="buttonModal" onClick={() => setModalBois(false)}>
          X
        </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
            <iframe
            className="ListEquipement"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "80%",
              height: "1320px",
              overflow: "hidden",
            }}
            src="./assets/bois_noir.html"
            ></iframe>
                      <iframe
            className="ListEquipementMobile"
            title="ListEquipement"
            style={{
              borderStyle: "none",
              width: "100%",
              height: "700px",
              overflow: "hidden",
            }}
            src="./assets/bois_noirMobile.html"
          ></iframe>
            </div>

            </div>
          </div>
        ) : null}
        </div>
     
        <p className="hebergementPCenter">
          Bétikuré présentant des dénivellés, nous conseillons aux personnes
          ayant des difficultés de déplacement de nous en informer afin de
          pouvoir leur proposer les hébergements les plus adaptés.
        </p>
        <div className='buttonContainerHebergement'>
        <button className='active' onClick={() =>  {setAutrePhotos(true);goToGalery()} }>Gallerie d'images</button>
        </div>

      </div>

  <div ref={refLink1} id="bottomHebergementContainer">
  {
      autrePhotos &&

      <CarouselReservation hebergement={"all"} />
  }



</div>
<br />
    </div>
  );
}

export default Hebergement