import React from "react";
import img1 from '../images/Planche_photos.png'
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
} from "@react-google-maps/api";


function MyGoogleMap() {
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: -21.562193,
    lng: 165.527985,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDcyk88xP_KoEOBxQIkDHD25526cmYL9Hg",
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);

    map.fitBounds(bounds);
    map.setOptions({ draggable: false });

    // Me Rend le bon zoom a l'affichage
    var listener = window.google.maps.event.addListener(
      map,
      "idle",
      function () {
        if (map.getZoom() > 16) map.setZoom(10);
        window.google.maps.event.removeListener(listener);
      }
    );

    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <div className="mapGoogle">
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapTypeId={"hybrid"}
        // mapTypeId={"plan"}
      >
        <Marker
          position={{ lat: -21.562193, lng: 165.527985 }}
          clickable={true}
        />

        <></>
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}

function Lodge() {


  return (
    <div  className="lodge">
      <div className="paddingTopMobile"></div>
      <div className="logdeContent">
        <div className="lodgeShadows">
          <h1 className="lodgeH1"> Le Lodge
          </h1>
          <div className="imageOneLodge"></div>
          <h2>A propos de Betikure</h2>

          <p>
            Dans un parc de 20 hectares planté de centaines de palmiers et
            autres espèces, BETIKURE propose des prestations de qualité, la
            convivialité d'une table d'hôtes et toute l'attention d'une petite
            équipe dévouée à un nombre d'hôtes limité.
            </p>
         <p>
            Bétikuré est situé sur la commune de Bourail, idéalement placée sur
            la côte ouest à mi-chemin entre Nouméa et l'extrémité nord de la
            grande terre. Le lodge est situé à l'intérieur des terres à quelques
            kilomètres de la plage de la roche percée, spot de surf apprécié et
            de celle de Poé renommée en tant que spot de planche et de kite
            surf. Tout cela sur une partie de lagon classée au patrimoine
            mondial de l'Unesco.
          </p>
        

<img className="imgPlanche"  src={img1} alt="images nature betikure" />

       

          <h2>Où sommes nous ?</h2>
          

          <div className="mapaGoogle">
            <MyGoogleMap />
          </div>
          <a
            href="https://goo.gl/maps/C5BvPwa2nWHT6gj69"
            rel="noreferrer"
            target="_blank"
          >
            <span className="BetikureAdress">Lien Google Map</span>{" "}
          </a>

          <div className="lodgeWhereTofindUs">
            <h4>Depuis NOUMEA ou le Sud :</h4>
            <p>
            Avant Bourail Sur la RT1, 6 kilomètres avant Bourail, sur votre droite un panneau au niveau d’un carrefour marqué d’une grande croix blanche vous indique la route de la
Taraudière. Cette route passe par un petit pont et se dirige vers l’hippodrome, lieu de la célèbre foire de Bourail. Vous passez devant la Tennesse Farm. En arrivant sur
l’hippodrome un panneau Bétikuré sur votre droite vous invite à continuer tout droit (panneau Bourail sur votre gauche). Suivre la route sur 2 km jusqu’à une nouvelle
bifurcation. Ne pas prendre la route qui tourne à gauche (retour vers Bourail), continuer tout droit … vous rentrez dans la vallée Colomina.
            </p>
            <h4>De BOURAIL ou depuis le Nord :</h4>
            <p>
            Arrivés dans le village de Bourail, prendre à gauche si vous venez du Nord, à droite si vous venez du Sud ou de Noumea, la route de la TARAUDIERE qui se trouve en face de
la Mairie. Après 300 m un panneau « Betikuré » vous invite à tourner à droite. Suivre la route sur 4 km jusqu’au premier carrefour ou vous tournerez à gauche pour rentrer

dans la vallée Colomina.
            </p>
            <h4>Une fois dans la vallée Colomia :</h4>
            <p>
   Après 300 m, sur votre droite un panneau « Bétikuré » vous indique l’entrée de de la piste. Vous pouvez être amenés à ouvrir 2 barrières si du bétail a été mis à paître.
Pensez à les refermer derrière vous… vous êtes en brousse ! Continuez tout droit sur 300 m jusqu’à rencontrer le panneau de bienvenue et les lanternes du Bétikuré Parc
Lodge.
            </p>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Lodge;
