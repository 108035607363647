import React, { useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";



export const CarouselItem = ({ children, width, activityName }) => {

  return (
    <div className="carousel-item" style={{ width: width }}>
      {children}
    </div>
  );
};
const Carousel = ({ children }) => {
  
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (!paused) {
  //       updateIndex(activeIndex + 1);
  //     }
  //   }, 3000);

  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div
      {...handlers}
      className="carousel"
      onMouseEnter={() => setPaused(true)}
      onMouseLeave={() => setPaused(false)}
    >
      <div className="indicators">
        {React.Children.map(children, (child, index) => {
          return (
            <button
              className={`${index === activeIndex ? "active" : ""}`}
              onClick={() => {
                updateIndex(index);
              }}
            >
              {children[index].props.activityName}
              {/* {children[index].props.children.props.children[0].props.children} */}
              {/* {console.log(children[index].props.children.props.children[0].props.children)} */}
              {/* {console.log(children[index].props.children)} */}
            </button>
          );
        })}
      </div>

      {/* Ordi////////////////////// */}
      <div
        className="inner desktop"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>

      {/* Mobile////////////////////// */}
      {/* <div className="mobileIndicator">
        <i
          className="fa-solid fa-arrow-left"
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        ></i>
        <i
          className="fa-solid fa-arrow-right"
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        ></i>
      </div> */}
      <div
        className="inner mobile"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, { width: "100%" });
        })}
      </div>
    </div>
  );
};

export default Carousel;
