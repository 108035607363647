import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hebergement from "./Pages/Hebergement";
import Index from "./Pages/Index";
import Lodge from "./Pages/Lodge";
import Prestations from "./Pages/Prestations";
import Reservation from "./Pages/Reservation";
import Restauration from "./Pages/Restauration";
import PaimentConfirmation from "./Pages/PaimentConfirmation";
import PaiementEchec from "./Pages/PaiementEchec";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/paiement_de_test" element={<PaimentConfirmation />} />
        <Route path="/paiement_echec" element={<PaiementEchec/>} />
        {/* <Route path="/lodge" element={<Lodge />} />
        <Route path="/hebergement" element={<Index />}  />
        <Route path="/restauration" element={<Index />}  />
        <Route path="/prestations" element={<Index />} />
        <Route path="/reservation" element={<Index />}  /> */}
        <Route path="*" element={<Index />}  />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
