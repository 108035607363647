import React from "react";
import Carousel, { CarouselItem } from "../Components/Carousel";

function Prestation() {
  const randonnée = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Randonnées à pied</h2>
          <p>
            Si Bétikuré propose un parcours sportif de 40 minutes qui permet de
            découvrir de belles vues sur l’arrière-pays c’est surtout les
            sentiers des trois baies ou ceux du Domaine de Déva qui avec quatre
            sentiers pédestres pour un total de 23,5 km vous offriront
            l’occasion de découvrir des points de vue extraordinaires sur la
            lagon.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.deva.nc/a-pied/les-sentiers-pedestres-de-deva/"
          >
            https://www.deva.nc/a-pied/les-sentiers-pedestres-de-deva/{" "}
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/randonnée.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );

  const Acceuil = (
    <div className="carouselContent carouselContentAcceuil ">
      <div className="acceuilActivity">
      <p>
          La région de BOURAIL est propice à la découverte de la culture locale
          ainsi qu'à la pratique d'un grand nombre d'activité. Afin de vous
          garantir un séjour inoubliable, Betikure est en mesure de vous
          conseiller ou d'organiser des journées qui marqueront votre séjour.
        </p>
      <b>  <p>Fidèle à nos principes, Bétikuré ne demande aucune commision aux prestataires dont il présente les activités et qu'il recommannde à sa clientèle.</p></b>

      {/* <p>Faite une virée en jet ski, Contact au 75 21 33</p> */}
      <div
        className="imageCarousel imageTop"
        style={{
          backgroundImage: "url(./assets/images/activités/activity.jpg)",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width : "100%",
        }}
        ></div>
        </div>
    </div>
  );
  const massage = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Massage</h2>
          <p>
            Sur rendez-vous, notre masseuse, adepte du Maho No Tsubo pourra vous
            masser dans votre hébergement ou dans le Faré au-dessus du lac.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/cocooning.nc/"
          >
            https://www.facebook.com/cocooning.nc/
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/massage.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );

  const bateau = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
        <h2>Bateau à fond de verre et randonnées palmées</h2>
          <p>
          Pour découvrir la faune et la flore du lagon et sa barrière de corail inscrit au patrimoine mondial de l’Unesco Ouest corail vous propose 4 excursions différentes et des randonnées palmées. 
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ouest-corail.nc/fr/accueil/"
          >
           https://www.ouest-corail.nc/fr/accueil/
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/bateau.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const visiteNautique = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Visite nautique guidée, éco-tour taxi boat</h2>
          <p>
          Cet éco-tour vous mènera de la mangrove de la Néra, aux fausses passes du grand récif, en passant par l’île verte, la plage et les falaises de la Roche percée, le bonhomme et la baie des tortues ; lieux chargés de mythes et de légendes avec observation des dauphins et des tortues. Dans le respect de l’environnement, manu propose aux visiteurs la balade « éco-découverte », une immersion dans le milieu naturel avec un programme très nature à forte dose éducative.
Si vous souhaitez plonger avec palmes, masque et tuba à proximité du grand récif, vous aurez la possibilité d’observer les nombreux habitants du récif comme les raies aigles et les tortues 
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nekweta.com/fr/tarifs"
          >
        https://nekweta.com/fr/tarifs
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/visiteNautique.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const skydiving = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Parachutisme et chute libre</h2>
          <p>
          Noumea Skydive vous propose des baptêmes de chute libre en parachute biplace à partir de Poé Bourail.
          </p>
          <p>Cette zone est juste un rêve de par ses couleurs extraordinaires et conditions particulièrement propices au parachutisme. Probablement une des plus belles zones de saut en parachute du monde ! Classée au patrimoine mondial de l’UNESCO, associez les sensations à la vue époustouflante du lagon de Poé !
</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.parachutisme.nc/"
          >
https://www.parachutisme.nc/
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/skydiving.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const musée = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Musée</h2>
          <p>
            Ce musée permet de découvrir l'histoire de la Nouvelle Calédonie. La
            colonisation est représentée via plusieurs photos et objets
            notamment la reconstitution d’une habitation de colon ou la célèbre
            guillotine en parfait état. Enfin la culture kanak n’est pas en
            reste avec une belle case traditionnelle, des pétroglyphes et des
            objets de la vie quotidienne.
          </p>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/musée.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const plage = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
      <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/plages.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="informationsContainerActivity">
          <h2>Les plages</h2>
          
          <p>
            Bourail dispose de deux magnifiques plages très différentes, celle
            de la Roche percée avec ses rouleaux, célèbre comme lieu de ponte
            des tortues et celle de Poé, 18 km de sable blanc au bord d’un lagon
            peu profond propice à la baignade et au snorkeling. Couchers de
            soleil magiques !!
          </p>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/kite.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>

      </div>
    </div>
  );

  const kite = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Kyte surf</h2>
          <p>
          Le lagon de Poé, peu profond, offre un spot parfait pour le kite
            surf lorsque le vent souffle. Idéal pour tous les niveaux même
            débutants mais aussi lieu de la finale des Championnats du monde de
            kitesurf !

          </p>
          
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/kite.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const market = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Marché</h2>
          <p>
          Les vendredis et samedis matin à la halle située au nord du village. Vous y trouverez des fruits et légumes de saison, des confitures, des gâteaux, du miel, des plats, des bouquets de fleurs, des plantes et plein d'autres choses. Les dames du marché sont adorables et discutent très facilement.
          </p>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/market.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const location = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Location kayaks et funboats</h2>
          <p>
          Poé Fun Loc propose de classiques locations de kayaks et d’originales locations de funboats, petits catamarans très faciles d’utilisation qui conviennent aussi bien aux enfants qu’aux parents. Sébastien loue également des bateaux à pédales avec toboggan, mais aussi des paddles en mousse pour se balader sur un des plus beaux lagons de Nouvelle-Calédonie. La plupart des activités se font à marée haute, se renseigner au préalable pour ne pas avoir de mauvaise surprise.
          </p>
          <a href="tel:+687 92 97 61">+687 92 97 61</a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/location.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );
  const ULM = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Vols en ULM</h2>
          <p>
          Du récif lenticulé au sud au Domaine de Déva au nord, la zone côtière de Bourail offre une époustouflante variété de paysages et un spot quasi idéal pour une expérience de vol en ULM
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.sudtourisme.nc/offres/cap-ulm-centre-air-passion-bourail-fr-2771687/"
          >
   https://www.sudtourisme.nc/offres/cap-ulm-centre-air-passion-bourail-fr-2771687/
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.ulm-hydravion-poe.com/fr/"
          >
https://www.ulm-hydravion-poe.com/fr/
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/ulm.jpg)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );

  const surf = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Surf</h2>
          <p>
            Le seul beach break de Nouvelle-Calédonie se trouve à la roche
            percée (à 10 minutes de voiture de Betikure). Il est possible de
            louer du matériel sur place. D'autres spots au large sont
            accessibles par bateau (pour surfers de bon niveau). A Nëkwéta, le
            surf se pratique sur le récif et à l’embouchure de la rivière La
            Néra par forte houle. Les vagues, que l’on peut aisément classer
            parmi le top 20 du pacifique Sud , sont situés autour de la passe de
            Bourail et accessibles uniquement par bateau. Manu vous accompagnera
            sur un panel de vagues (Gouaro, Snorky, la gauche de l’île Verte,
            les ronfleurs et secret) pour la plus éloignée à 20 minutes de
            navigation. Les vagues les plus surfées sont Gouaro et Secret ; mais
            si les conditions ne sont pas optimales, Manu, votre guide, pourra
            proposer de vous diriger sur un des autres spots.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://nekweta.com/fr/tarifs"
          >
        https://nekweta.com/fr/tarifs
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/surf.png)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );

  const kayak = (
    <div className="carouselContent">
      <div className="informationsContainerActivity">
        <p>En mer ou en rivière , location et transport possible</p>
      </div>
      <div
        className="imageCarousel"
        style={{
          backgroundImage: "url(./assets/images/activités/kayak.jpg",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );
  const snorkeling = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Plongée</h2>
        <p>
        À Bourail, le lagon est étroit et peu profond, les plongées s’effectuent donc sur la pente externe du
récif, au niveau des passes, favorisant le passage des pélagiques. Les rencontres sous-marines sont
bien souvent exceptionnelles ! Le maintien de la beauté des sites est une préoccupation majeure de
Bourail Aquadiving. Les moniteurs veillent à vous sensibiliser à la fragilité des écosystèmes. Des
conférences sont régulièrement organisée pour mieux connaître les requins.
        </p>
        <a href="https://bourailaquadiving.bookme.nc/fr/accueil/">
        https://bourailaquadiving.bookme.nc/fr/accueil/
        </a>
      </div>
      <div
        className="imageCarousel"
        style={{
          backgroundImage: "url(./assets/images/activités/scuba.png",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      </div>
    </div>
  );
  const vtt = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>VTT</h2>
        <p>
        Le domaine de Deva propose 17 pistes de VTT de tous niveaux. Vous pourrez louer auprès de Deva's bike, des VTT classique ou à assistance électrique. Casque fourni avec votre vélo. Vous pourrez vous laisser aller au gré de vos désirs sur les 200 kilomètres de pistes balisées et labellisée FFC du domaine de Deva. De la carrossable aux single-tracks en herbe en passant par les crêtes vous offrant des vues incontournables sur le lagon et en slalomant sous les forêts de niaoulis.
        </p>
        <a     target="_blank"
            rel="noreferrer" href="https://www.devasbike.nc/fr/accueil/">
        https://www.devasbike.nc/fr/accueil/
        </a>
      </div>
      <div
        className="imageCarousel"
        style={{
          backgroundImage: "url(./assets/images/activités/vtt.png",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      </div>
    </div>
  );
  const cheval = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Balade à cheval</h2>
          
        <p>
        Marion organise pour tous niveaux des balades à cheval entre terre et mer sur le magnifique domaine de Deva et même des séances de baignades des chevaux…
        </p>
        <a     target="_blank"
            rel="noreferrer" href="https://www.farwestranch.nc/fr/accueil/">
        https://www.farwestranch.nc/fr/accueil/
        </a>
        <a href="mailto:marion@farwestranch.nc">marion@farwestranch.nc</a>
      </div>
      <div
        className="imageCarousel"
        style={{
          backgroundImage: "url(./assets/images/activités/cheval.jpg",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      </div>
    </div>
  );

  const golf = (
    <div className="carouselContent">
      <div className="carouselActivityContainer">
        <div className="informationsContainerActivity">
          <h2>Le GOLF</h2>
          <p>
            Le Golf de Déva s’étend sur un vaste et magnifique domaine en
            bordure du lagon. Les fairways, plutôt larges et accueillants,
            légèrement ondulés, serpentent majestueusement entre les niaoulis,
            arbres endémiques lointains cousins de l’eucalyptus. 18 trous de
            6663 m, par de 72, practice de 25 poste sur gazon …{" "}
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://fr.exclusivgolf-deva.com/Parcours/carte-de-score"
          >
            http://fr.exclusivgolf-deva.com/Parcours/carte-de-score{" "}
          </a>
          <a href="mailto:directeur@exclusivgolf-deva.com">
            directeur@exclusivgolf-deva.com
          </a>
        </div>
        <div
          className="imageCarousel"
          style={{
            backgroundImage: "url(./assets/images/activités/golf.png",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </div>
  );

  const moreActivity = (
    <div className="carouselContent">
      <p>
        Bien d'autres activités sont possibles, demandez à notre équipe de vous
        conseiller ou prenez simplement le temps de vous reposer et de profiter
        du cadre de Betikure ...
      </p>
      <div
        className="imageCarousel"
        style={{
          backgroundImage: "url(./assets/images/activités/11098-min.jpg",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
    </div>
  );

  return (
    <div className="prestation">
      {/* <NavBar /> */}
      {/* <div className="paddingTopMobile"></div> */}
      <br />
             <h1>Activités</h1>
      <div className="prestationContent">
   
      <div className="resumePrestationTop">

      </div>
        <Carousel>
          <CarouselItem activityName={"Accueil"}>{Acceuil}</CarouselItem>
          <CarouselItem activityName={"Le Golf"}>{golf}</CarouselItem>
          <CarouselItem activityName={"Les plages"}>{plage}</CarouselItem>
          <CarouselItem activityName={"Kite surf"}>{kite}</CarouselItem>
          <CarouselItem activityName={"Musée"}>{musée}</CarouselItem>
          <CarouselItem activityName={"Randonnée"}>{randonnée}</CarouselItem>
          <CarouselItem activityName={"Massage"}>{massage}</CarouselItem>
          <CarouselItem activityName={"Surf"}>{surf}</CarouselItem>
          <CarouselItem activityName={"Bateau à fond de verre et randonnées palmées"} > {bateau}</CarouselItem>
          <CarouselItem activityName={"Plongée"}>{snorkeling}</CarouselItem>
          <CarouselItem activityName={"Balade à cheval"}> {cheval} </CarouselItem>
          <CarouselItem activityName={"Visite nautique"}> {visiteNautique} </CarouselItem>
          <CarouselItem activityName={"Vols en ULM"}> {ULM}</CarouselItem>
          <CarouselItem activityName={"Parachutisme"}> {skydiving}</CarouselItem>
          <CarouselItem activityName={"Marché"}> {market}</CarouselItem>
          <CarouselItem activityName={"VTT"}> {vtt}</CarouselItem>
          <CarouselItem activityName={"Location kayaks et funboats"}>{location} </CarouselItem>
        </Carousel>

      </div>
    </div>
  );
}

export default Prestation;
