import React from "react";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";

export default function PaiementEchec() {
  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();
  return (
    <div className="PaiementEchecPage">
      <Navigation />
      <div className="PaimentEchecContainer">
        <h1>Votre réservation</h1>
        <div className="PaimentEchec">
          <p>Nous vous remercions pour votre demande de réservation. </p>
          <p>
            <b>
              Le règlement de l’acompte nécessaire à la confirmation de votre
              réservation n’a pas pu aboutir. Votre réservation n’est donc pas confirmée.
            </b>
          </p>
          <p>
          Si vous souhaitez la maintenir et que votre date d’arrivée est prévue à plus de 3 jours
nous vous proposons de renouveler votre demande en utilisant la procédure de
règlement différé.
          </p>
          <p>
          Vous pouvez aussi nous communiquer vos coordonnées de CB par téléphone au 46 23
60 ou par mail à  <a href="mailto:reservation@betikure.com ">reservation@betikure.com </a> afin d’obtenir la confirmation immédiate
de votre réservation.
          </p>
          <div className="separator_mt_2">
            <p className="small">
              <b>              Nous attirons votre attention sur le fait qu’en l’absence de
              confirmation cette réservation sera annulée.</b>
              

            </p>
            <p>Nous attendons la confirmation de votre
demande par un nouveau règlement en ligne ou par l’utilisation du règlement en différé
pour prendre en compte votre demande.</p>
            <p className="small">L’équipe du Bétikuré Parc Lodge</p>
          </div>
          <a className='retour_ehec' href="/">Retour sur la page d'acceuil</a>
        </div>
      </div>
      <Footer />
    </div>
  );
}
