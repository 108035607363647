import React from "react";

function Restauration() {
  return (
    <div className="restauration">
      <div className="paddingTopMobile"></div>

      <h1>Restauration</h1>
      <div className="restaurationContent">
        <div className="cardRestaurationContainer">
          <h2 className="title">Le petit déjeuner </h2>
          <div className="cardTextAndImageContainer">
            {/* <img src={img1} className="cardImageRestauration imagePetitDejeunerRestauration"></img> */}
            <p>
              Petit déjeuner continental : salade de fruits frais provenant de
              la propriété pour certains en fonction de la saison (Litchis,
              mangues, Pitayas,…), viennoiseries cuites le matin, confitures
              locales, jus de fruits, pain de chez Fred.
            </p>
            <div className="cardImageRestauration imagePetitDejeunerRestauration"></div>
          </div>
          <p className="cardTarifRestauration">
            Tarifs : 1680 XPF / 980 XPF pour les enfants de moins de 6 ans /
            Œufs en suppléments : 500 XPF
          </p>
        </div>

        <div className="cardRestaurationContainer">
          <h2 className="title">Le déjeuner </h2>
          <div className="cardTextAndImageContainer dejeuner">
            <p>
              Le Bétikuré propose pour déjeuner sans réservation un menu de
              Bruschetta gourmandes, Saumon frais et crème de ciboulette, jambon
              serrano et parmesan, Chèvre et tomates séchées, confit de canard
              gratiné, wahoo fumé, etc … servi avec une salade verte, des chips
              de bacon et œuf poché 2 bruschettas au choix et salade.
            </p>
            <div className="cardImageRestauration imageDejeunerRestauration"></div>
          </div>
          <div className="cardTarifRestauration">
            <p> Tarifs : 2450 XPF</p>
            <p> En option : Frites et café gourmand</p>
          </div>
        </div>

        <div className="cardRestaurationContainer">
          <h2 className="title">Le diner </h2>
          <div className="cardTextAndImageContainer diner">
            <div className="cardImageRestauration imageDinerRestauration"></div>
            <p>
              Sur réservation et ouvert à la clientèle extérieure le diner est
              proposé sous forme de menu unique (Entrée, Plat, dessert) mais
              vous pourrez opter pour 2 ou trois plats. Malgré le principe de
              menu unique nous trouvons toujours une solution correspondant aux
              différentes options de nos clients. La cuisine marie les
              différentes influences calédoniennes et propose des plats allant
              du typiquement « frenchie » aux préparation locales. Nous
              privilégions des circuits courts, l’excellente viande provient
              essentiellement de nos deux bouchers et éleveurs du village, les
              salades, herbes et certains légumes de notre voisin … Notre
              fournisseur de poissons vous permettra de gouter au mahi mahi, au
              perroquet ou au wahoo sans parler de nos crevettes locales !
            </p>
          </div>
          <br />
          <div className="cardTarifRestauration">
            <p>
              {" "}
              Tarifs : 2 plats 3550 XPF - 3 plats 4150 XPF - menu enfant 1680
              XPF
            </p>
            <p> Carte de vins et alcools</p>
          </div>
        </div>

        <div className="cardRestaurationContainer">
          <h2 className="title">Le lieu</h2>
          <div className="cardTextAndImageContainer diner">
            <div className="cardImageRestauration imageLieu2Restauration"></div>
            <p>
              La salle de restauration ouvre sur la piscine et le parc. Un bar
              est à votre disposition. C’est un lieu de convivialité qui
              correspond bien au nom de Bétikuré qui est tiré d’une langue
              locale et signifie « partager marmite ».
            </p>
              <div className="cardImageRestauration imageLieuRestauration"></div>
          </div>
        </div>

        <div className="cardRestaurationContainer">
          <h2 className="title">Le Faré</h2>
          <div className="cardTextAndImageContainer">
            <p>
              Et si vous désirez « griller » vous-même, nous mettons
              gratuitement à votre disposition un vaste Faré avec une belle vue
              sur la vallée équipé de 2 planchas gaz et de deux grandes tables à
              partager.
            </p>

            <div className="cardImageRestauration imageFareRestauration"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Restauration;
