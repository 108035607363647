import React from "react";
import { useState, useEffect, useRef } from "react";
import Footer from "../Components/Footer";
import Calendar from "../Components/Calendar/Calendar";
import { format } from "date-fns/esm";
import { differenceInCalendarDays, addDays } from "date-fns";
import FetchReservation from "../Components/Calendar/FetchReservation";
import Legend from "../Components/Calendar/Legend";

const Reservation = ({ dispo }) => {
  const [modal, setModal] = useState(false);
  const [modalSuite, setModalSuite] = useState(false);
  const [modalChambre, setModalChambre] = useState(false);
  const [modalBois, setModalBois] = useState(false);
  const [dateSelectioned, setDateSelectioned] = useState([]);
  const [roomSelected, setRoomSelected] = useState([]);
  const [lot, setLot] = useState("1");
  const [debut, setDebut] = useState(format(new Date(), " dd/MM/yyyy "));
  const [fin, setFin] = useState(format(new Date(), " dd/MM/yyyy "));
  const [adulte, setAdulte] = useState(2);
  const [enfant, setEnfant] = useState(0);
  const [monnaie, setMonnaie] = useState("xpf");
  const [checkedOne, setCheckedOne] = useState(false);
  const [proChecked, setProChecked] = useState(false);
  const reservations = FetchReservation();
  const windowSize = useRef(window.innerWidth);

  /****room selected go from calendar to resa form  */
  function switchLot(state) {
    switch (state) {
      case "Villas":
        return "lot1";
      case "Chambres":
        return "lot2";
      case "Suites":
        return "lot3";
      case "Bois Noir":
        return "lot4";
      default:
        return "";
    }
  }
  /******************************************** */
  /****max people for resa*/
  function switchMaxPeople(state) {
    switch (state) {
      case "Villas":
        return 9;
      case "Chambres":
        return 3;
      case "Suites":
        return 6;
      case "Bois Noir":
        return 4;
      default:
        return "";
    }
  }
  /******************************************** */

  /**** selected date go from calendar to resa form */

  useEffect(() => {
    if (dateSelectioned.length == 2) {
      if (roomSelected[0] === roomSelected[1]) {
        setDebut(format(dateSelectioned[0], " dd/MM/yyyy "));
        // setFin(format(dateSelectioned[1], " dd/MM/yyyy "));

        const finPlusOneDay = addDays(dateSelectioned[1], 1);

        if (dateSelectioned[0] < dateSelectioned[1]) {
          setFin(format(finPlusOneDay, " dd/MM/yyyy "));
        } else {
          setFin(format(dateSelectioned[0], " dd/MM/yyyy "));
        }

        setDateSelectioned([]);
        setRoomSelected([]);
        setLot(roomSelected[1]);
      } else {
        setDateSelectioned([dateSelectioned[1]]);
        setRoomSelected([roomSelected[1]]);
      }
    }
  }, [dateSelectioned]);

  /******************************************** */

  /**** convet string to date ******* */
  let start = debut.split("/");
  let startToDate = new Date(start[2], start[1] - 1, start[0]);
  let end = fin.split("/");
  let endToDate = new Date(end[2], end[1] - 1, end[0]);
  const differenceInrDays = differenceInCalendarDays(endToDate, startToDate);

  const boxContainerAdulte = () => {
    return (
      <div className="adulteEnfantContainer">
        <div className="box">
          <p> Adultes et Enfants (2 ans et plus)</p>
          <div className="quantity">
            <span
              onClick={() => setAdulte(adulte + 1)}
              className="quantity-add quantity-button"
            ></span>
            <input readOnly type="number" step="1" min="1" value={adulte} />
            <span
              onClick={() => {
                adulte > 1 ? setAdulte(adulte - 1) : setAdulte(0);
              }}
              className="quantity-remove quantity-button"
            ></span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="reservation" id="reservationTop">
      {/* <NavBar /> */}
      <div className="paddingTopMobile"></div>
      {/* <h1 style={{ visibility: "hidden", height: "1px" }}>Disponibilité</h1> */}
      {/* <h1>Réservation</h1> */}

      <div className="reservationContent">
        {modal ? (
          <div className="overlay">
            <div className="modal">
              <button className="buttonModal" onClick={() => setModal(false)}>
                X
              </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
                <iframe
                  className="ListEquipement"
                  title="ListEquipement"
                  style={{
                    borderStyle: "none",
                    width: "100%",
                    height: "1320px",
                    overflow: "hidden",
                  }}
                  src="./assets/villa.html"
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}

        {modalSuite ? (
          <div className="overlay">
            <div className="modal">
              <button
                className="buttonModal"
                onClick={() => setModalSuite(false)}
              >
                X
              </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
                <iframe
                  className="ListEquipement"
                  title="ListEquipement"
                  style={{
                    borderStyle: "none",
                    width: "80%",
                    height: "1320px",
                    overflow: "hidden",
                  }}
                  src="./assets/suite.html"
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}

        {modalChambre ? (
          <div className="overlay">
            <div className="modal">
              <button
                className="buttonModal"
                onClick={() => setModalChambre(false)}
              >
                X
              </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
                <iframe
                  className="ListEquipement"
                  title="ListEquipement"
                  style={{
                    borderStyle: "none",
                    width: "80%",
                    height: "1320px",
                    overflow: "hidden",
                  }}
                  src="./assets/chambre.html"
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}

        {modalBois ? (
          <div className="overlay">
            <div className="modal">
              <button
                className="buttonModal"
                onClick={() => setModalBois(false)}
              >
                X
              </button>
              <h4>Liste des équipements</h4>
              <br />
              <div className="iframeModal">
                <iframe
                  className="ListEquipement"
                  title="ListEquipement"
                  style={{
                    borderStyle: "none",
                    width: "80%",
                    height: "1320px",
                    overflow: "hidden",
                  }}
                  src="./assets/bois_noir.html"
                ></iframe>
              </div>
            </div>
          </div>
        ) : null}

        <div className="calendarsContainer">
          <div className="disponibiliteContainer">
            <h2>Disponibilités</h2>

            {reservations && dispo ? (
              <Calendar
                roomSelectedType={setRoomSelected}
                dateSelectioned={setDateSelectioned}
                setmodalChambre={setModalChambre}
                setmodalVilla={setModal}
                setmodalSuite={setModalSuite}
                setmodalBois={setModalBois}
              />
            ) : (
              <p>
                {" "}
                ! Un problème est survenu lors du chargement du calendrier.
                Veuillez rafraîchir la page ou réessayer ultérieurement
              </p>
            )}
          </div>

          {/* <p className="clicForMoreInfo">
           Equipements
          </p> */}

          <Legend />

          <div className="reservationContainerGrid">
            <h2>Réservation</h2>
            {fin !== format(new Date(), " dd/MM/yyyy ") ? (
              <div className="iframeresaDesktop">
                {switchLot(lot) !== "lot4" && !checkedOne ? (
                  adulte > switchMaxPeople(lot) ? (
                    <div className="errorMessage">
                      <p>
                        Vous avez sélectionné une <b>{lot.slice(0, -1)} </b>
                      </p>
                      <p>
                        le nombre de personnes autorisés maximum est{" "}
                        <b> {switchMaxPeople(lot)}</b>{" "}
                      </p>
                      <br />
                      <p>
                        Veuillez selectionner un <b>hébergement </b>plus adapté.
                      </p>{" "}
                      <br />
                      <p>
                        Vous pouvez cependant réserver de multiples <b>{lot}</b>
                      </p>
                    </div>
                  ) : null
                ) : null}

                {fin !== format(new Date(), " dd/MM/yyyy ")
                  ? boxContainerAdulte()
                  : null}

                <>
                  <div className="iframeResaText">
                    {fin !== format(new Date(), " dd/MM/yyyy ") ? (
                      <>
                        <div className="reservationDetailsContainer">
                          <i className="gg-arrow-down "></i>
                          <p>
                            Pour
                            {Array.apply(null, { length: adulte }).map(
                              (e, i) => (
                                <i className="fa-solid fa-person" key={i}></i>
                              )
                            )}{" "}
                            {adulte !== 1 ? "personnes" : "personne"}
                          </p>

                          <p className="dateFromTo">
                            <i className="fa-solid fa-calendar-days"></i> Du{" "}
                            <b> {debut}</b> au <b>{fin}</b> ({" "}
                            {differenceInrDays === 0 ? "1" : differenceInrDays}{" "}
                            {differenceInrDays > 1 ? "nuits" : "nuit"} )
                          </p>
                          {/* 
                          {debut && (
                            <>
                              <p>
                                {" "}
                                {!checkedOne &&
                                fin !== format(new Date(), " dd/MM/yyyy ") ? (
                                  <span>
                                    {" "}
                                    <i className="fa-solid fa-house"></i>{" "}
                                    Hébergement : <b>{lot}</b>{" "}
                                  </span>
                                ) : null}
                                {!checkedOne ? (
                                  lot === "Villas" ? (
                                    <button onClick={() => setModal(!modal)}>
                                     Consulter liste équipements
                                    </button>
                                  ) : lot === "Suites" ? (
                                    <button
                                      onClick={() => setModalSuite(!modal)}
                                    >
                                         Consulter liste équipements
                                    </button>
                                  ) : lot === "Chambres" ? (
                                    <button
                                      onClick={() => setModalChambre(!modal)}
                                    >
                                        Consulter liste équipements
                                    </button>
                                  ) : null
                                ) : null}
                              </p>
                            </>
                          )} */}
                        </div>

                        {lot !== "1" ? (
                          <div className="checkbox-wrapper-6">
                            <input
                              onChange={(e) => setCheckedOne(!checkedOne)}
                              value={checkedOne}
                              className="tgl tgl-light"
                              id="cb1-6"
                              type="checkbox"
                            />
                            <label className="tgl-btn" htmlFor="cb1-6" />
                            <span>Voir tous les hébergements disponibles</span>
                          </div>
                        ) : null}

                        <label>
                          <input
                            type="checkbox"
                            // checked={checked}
                            onChange={() => setProChecked(!proChecked)}
                          />
                          Professionnels en déplacement déjà enregistrés auprès
                          de Bétikuré
                        </label>

                        <div className="remiseContainer">
                          {!proChecked ? (
                            <div className="annulationArray">
                              <h4>Acompte :</h4>
                              <p>
                                La réservation nécessite un acompte de 50 %.
                                L’acompte sera déduit de votre facture en fin de
                                séjour, conservé ou remboursé en tout ou partie
                                en fonction des conditions d’annulation prévues
                                (voir conditions générales).
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              </div>
            ) : null}

            {fin !== format(new Date(), " dd/MM/yyyy ") ||
            windowSize.current < 1500 ? (
              <>
                <div className="iframeRightContainer">
                  {fin !== format(new Date(), " dd/MM/yyyy ") ||
                  windowSize.current < 1500 ? (
                    <p className="erreurHebergement">
                      Un message{" "}
                      <span className="erreurRed">
                        Aucun hébergement disponible
                      </span>{" "}
                      apparait lorsque le séjour demandé nécessite un changement
                      d’hébergement en cours de séjour. Merci dans ce cas de
                      contacter le Bétikuré <br />
                      <a href="mailto:reservation@betikure.com">
                        reservation@betikure.com
                      </a>
                    </p>
                  ) : null}
                  <iframe
                    className="iframeResaDesktopIframe"
                    title=""
                    style={{
                      borderStyle: "none",
                      width: "100%",
                      height: "970px",
                      background: "#FFF",

                      // marginLeft: "12vw"
                    }}
                    src={
                      !proChecked
                        ? `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&date_debut=${debut}&date_fin=${fin}&adultes=${adulte}&enfants=${enfant}nb_enfants=0&nbpers_max=0&adulte_obli=1&chambres=${
                            !checkedOne ? switchLot(lot) : ""
                          }`
                        : `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&date_debut=${debut}&date_fin=${fin}&adultes=${adulte}&enfants=${enfant}nb_enfants=0&nbpers_max=0&adulte_obli=1&chambres=${
                            !checkedOne ? switchLot(lot) : ""
                          }&provenance=8`
                    }
                  ></iframe>
                </div>
              </>
            ) : (
              <div className="waitingForDateSelection">
                <div className="nbPersonneSelectionContainer">
                  <div className="etapeContainer etape1">
                    <p className="numberEtapeReservation">1</p>

                    <div className="veuillezselectionnerdates">
                      <p>Selectionner le nombre de personnes :</p>

                      <div className="adulteEnfantContainer">
                        <div className="box">
                          <p> Adultes et Enfants (2 ans et plus)</p>
                          <div className="quantity">
                            <span
                              onClick={() => setAdulte(adulte + 1)}
                              className="quantity-add quantity-button"
                            ></span>
                            <input
                              readOnly
                              type="number"
                              step="1"
                              min="1"
                              value={adulte}
                            />
                            <span
                              onClick={() => {
                                adulte > 1
                                  ? setAdulte(adulte - 1)
                                  : setAdulte(0);
                              }}
                              className="quantity-remove quantity-button"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {adulte !== 0 ? (
                  <div className="etapeContainer">
                    <p className="numberEtapeReservation">2</p>
                    <div className="veuillezselectionnerdates arrows">
                      <i className="gg-arrow-down"></i>
                      <p>
                        Selectionner les nuits à réserver dans le planning
                        ci-dessus (2 clics pour une seule nuit).
                      </p>
                      <i className="gg-arrow-down"></i>
                    </div>
                  </div>
                ) : null}

                <div className="etapeContainer">
                  <p className="numberEtapeReservation">3</p>
                  <div className="veuillezselectionnerdates">
                    <p>
                      Après sélections des dates vous pourrez choisir le nombre
                      d'hébergement souhaité
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {fin !== format(new Date(), " dd/MM/yyyy ") ||
          windowSize.current < 1500 ? (
            <>
              <div className="pro_mobile_checkbox">
                <label>
                  <input
                    type="checkbox"
                    // checked={checked}
                    onChange={() => setProChecked(!proChecked)}
                  />
                  Professionnels en déplacement déjà enregistrés auprès de
                  Bétikuré
                </label>
              </div>

              <iframe
                className="iframeResaMobile"
                title="ResaMobile"
                style={{
                  borderStyle: "none",
                  width: "100%",
                  height: "1300px",
                  background: "white",
                }}
                src={
                  !proChecked
                    ? `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&
                    }`
                    : `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&provenance=8`
                }
                // }}
                // src={
                //   !proChecked
                //     ?
                //       `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&date_debut=${debut}&date_fin=${fin}&adultes=${adulte}&enfants=${enfant}nb_enfants=0&nbpers_max=0&adulte_obli=1&chambres=${
                //         !checkedOne ? "" : ""
                //       }`
                //     : `https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&date_debut=${debut}&date_fin=${fin}&adultes=${adulte}&enfants=${enfant}nb_enfants=0&nbpers_max=0&adulte_obli=1&chambres=${
                //         !checkedOne ? switchLot(lot) : ""
                //       }&provenance=8`
                // }
              ></iframe>
            </>
          ) : null}
          <div className="annulationArrayMobile">
            <h4>Acompte :</h4>
            <p>
              La réservation nécessite un acompte de 50 %. L’acompte sera déduit
              de votre facture en fin de séjour, conservé ou remboursé en tout
              ou partie en fonction des conditions d’annulation prévues (voir
              conditions générales).
            </p>
          </div>
        </div>
        <div className="planning">
          <h2 id="tarifs">Tarifs</h2>
          <div className="backgroundReduction">
          <p>Une réduction de 10 % est appliquée sur le séjour pour une réservation de deux nuits consécutives</p>
          <p>Une réduction de 15 % est appliquée sur le séjour pour une réservation de trois nuits ou plus consécutives</p>
          </div>

          {monnaie === "xpf" ? (
            <iframe
              className="planningIframe"
              id="xpfIframe"
              title="planningTarif"
              style={{
                borderStyle: "none",
                width: "100%",
                height: "300px",
                overflow: "hidden",
              }}
              src="./assets/XPF2.html"
            ></iframe>
          ) : (
            <iframe
              className="planningIframe"
              title="planningTarif"
              style={{
                borderStyle: "none",
                width: "95%%",
                height: "220px",
                overflow: "hidden",
              }}
              src="./assets/planningeur.html"
            ></iframe>
          )}
          <div className="buttonsMonnaie">
            <button
              className={monnaie === "xpf" ? "active" : "null"}
              onClick={() => setMonnaie("xpf")}
            >
              Francs Pacifique
            </button>
            <button
              className={monnaie === "Euros" ? "active" : "null"}
              onClick={() => setMonnaie("Euros")}
            >
              Euros
            </button>

            <p className="tarifsSup">
              Personnes supplémentaires{" "}
              {monnaie === "Euros" ? "23€/nuit" : "2950xpf/nuit"}{" "}
            </p>
          </div>
        </div>
      </div>

      <p style={{ textAlign: "center", margin: "1rem" }}>
        Les conditions de réservation, d’annulation et de séjour sont
        consultables dans{" "}
        <a
          style={{ color: "black" }}
          target="_blank"
          rel="noreferrer"
          href="https://manava.abricode.fr/comptes/betikureparclodge/ressources/public/conditions-generales.pdf"
        >
          les conditions générales
        </a>{" "}
      </p>
      <Footer />
    </div>
  );
};

export default Reservation;
