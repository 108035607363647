import React ,{useEffect} from "react";

import { Carousel } from "react-responsive-carousel";

const CarouselReservation = ({ hebergement }) => {



  
  
  useEffect(() => {
    
    const liCarousel = document.querySelectorAll('.thumb')
    const sliderCarousel = document.querySelector('.slider-wrapper')
    return () => {
      liCarousel.forEach(li => {
        return li.addEventListener('click',() => sliderCarousel.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        }))
        
      
      })
    }
  }, [])
  
  if(hebergement === "all"){


    return (
    <Carousel
    showStatus={false}
    preventMovementUntilSwipeScrollTolerance={true}
    swipeScrollTolerance={80}
    infiniteLoop={true}
    showIndicators={false}
    dynamicHeight={true}
    showThumbs={true}

  >
    <div>
      <img alt="betikure villa photos" src={process.env.PUBLIC_URL + "/assets/images/2.jpg"} />
    </div>
    <div>
      <img
        alt="betikure villa photos"
        src={process.env.PUBLIC_URL + "/assets/images/suite.jpg"}
      />
    </div>
    <div>
      <img alt="betikure villa photos" src={process.env.PUBLIC_URL + "/assets/images/1.jpg"} />
    </div>
    <div>
      <img
        alt="betikure villa photos"
        src={process.env.PUBLIC_URL + "/assets/images/room.jpg"}
      />
    </div>
    <div>
      <img
        alt="betikure villa photos"
        src={process.env.PUBLIC_URL + "/assets/images/room3.jpg"}
      />
    </div>
    <div>
      <img
        alt="betikure villa photos"
        src={process.env.PUBLIC_URL + "/assets/images/cuisine.jpg"}
      />
    </div>
    <div>
          <img
            alt="betikure chambre photos"
            src={process.env.PUBLIC_URL + "/assets/images/extnightpool.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/exnew.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/salleamanger.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/ext4.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure chambre photos"
            src={process.env.PUBLIC_URL + "/assets/images/beti_ext_night.jpg"}
          />
          
        </div>
   
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/lodgeviewfar.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/bois_noir.jpg"}
          />
        </div>
        <div>
          <img alt="betikure suite photos" src={process.env.PUBLIC_URL + "/assets/images/3.jpg"} />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/terrasseRoom.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/uh4-min.jpg"}
          />
        </div>
      </Carousel>
  )}
  if (hebergement === "villa") {
    return (
      <Carousel
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        infiniteLoop={true}
        showIndicators={false}
        dynamicHeight={true}
        showThumbs={true}
      >
        <div>
          <img alt="betikure villa photos" src={process.env.PUBLIC_URL + "/assets/images/2.jpg"} />
        </div>
        <div>
          <img
            alt="betikure villa photos"
            src={process.env.PUBLIC_URL + "/assets/images/suite.jpg"}
          />
        </div>
        <div>
          <img alt="betikure villa photos" src={process.env.PUBLIC_URL + "/assets/images/3.jpg"} />
        </div>
        <div>
          <img
            alt="betikure villa photos"
            src={process.env.PUBLIC_URL + "/assets/images/room.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure villa photos"
            src={process.env.PUBLIC_URL + "/assets/images/room3.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure villa photos"
            src={process.env.PUBLIC_URL + "/assets/images/cuisine.jpg"}
          />
        </div>
      </Carousel>
    );
  } else if (hebergement === "chambre") {
    return (
      <Carousel
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        infiniteLoop={true}
        showIndicators={false}
        dynamicHeight={true}
      >
        <div>
          <img
            alt="betikure chambre photos"
            src={process.env.PUBLIC_URL + "/assets/images/room.jpg"}
          />
        </div>

        <div>
          <img
            alt="betikure chambre photos"
            src={process.env.PUBLIC_URL + "/assets/images/terrasseRoom.jpg"}
          />
          
        </div>
      </Carousel>
    );
  } else if (hebergement === "suite") {
    return (
      <Carousel
        showStatus={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={80}
        infiniteLoop={true}
        showIndicators={false}
        dynamicHeight={true}
      >
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/room3.jpg"}
          />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/suite.jpg"}
          />
        </div>
        <div>
          <img alt="betikure suite photos" src={process.env.PUBLIC_URL + "/assets/images/3.jpg"} />
        </div>
        <div>
          <img
            alt="betikure suite photos"
            src={process.env.PUBLIC_URL + "/assets/images/terrasseRoom.jpg"}
          />
        </div>
      </Carousel>
    );
  }
};

export default CarouselReservation;
