import React from "react";

function Footer() {
  return (
    // <div className='footerImage' style={{backgroundImage: "url(./assets/images/bgnav.jpg)",backgroundPosition: "contain" , backgroundSize: "cover", height: '140px',   boxShadow: "-2px -13px 15px 6px rgba(0,0,0,0.29),-2px 30px 15px 6px rgba(0,0,0,0.29)"}} >Footer</div>
    <>
      <div className="line"></div>

      <div className="footer">
        <a href="#nav-container">
   
          <span className="BetikureFooter">Betikuré Parc Lodge </span>{" "}
        </a>
        <a
          href="https://goo.gl/maps/C5BvPwa2nWHT6gj69"
          rel="noreferrer"
          target="_blank"
        >
   
          <span className="BetikureAdress">
            La Taraudière, Boite 352, Bourail 98870, Nouvelle-Calédonie
          </span>
        </a>

        <a
          href="https://www.facebook.com/BetikureParcLodge"
          rel="noreferrer"
          target="_blank"
        >
          <svg
            className="glow"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
          >
            <path
              className="st0"
              d="M28.3 0.1H1.7c-0.9 0-1.6 0.7-1.6 1.6v26.5c0 0.9 0.7 1.6 1.6 1.6H16V18.4h-3.9v-4.5H16v-3.3c0-3.9 2.4-5.9 5.8-5.9 1.6 0 3.1 0.1 3.5 0.2v4l-2.4 0c-1.9 0-2.2 0.9-2.2 2.2v2.9h4.5l-0.6 4.5h-3.9v11.5h7.6c0.9 0 1.6-0.7 1.6-1.6V1.7C29.9 0.8 29.2 0.1 28.3 0.1z"
            ></path>
          </svg>
        </a>

        {/* <p className='webMaster'> <a href="mailto:cenerinok@gmail.com">Un problème ? Contactez le webMaster</a></p> */}
      </div>
    </>
  );
}

export default Footer;
