import React from "react";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";

function PaimentConfirmation() {
  const documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", documentHeight);
  documentHeight();
  return (
    <>
      <Navigation />
      <iframe
                  className="iframeResaDesktopIframe"
                  title=""
                  style={{
                    borderStyle: "none",
                    width: "100%",
                    height: "970px",
                    background: "#FFF",

                    // marginLeft: "12vw"
                  }}
                  src={`https://manava.abricode.fr/rest/iframe_resa_grp.php?code_ext=zYRota6aQe8ILZQ42&secure_key=f17a2c13249da1451ded715293e3ed89&calendrier=0&date_debut=19/03/2024&date_fin=20/03/2024&adultes=2&enfants=0nb_enfants=0&nbpers_max=0&adulte_obli=1&paiement_en_ligne=epaync`                       
                  }
                ></iframe>
      <Footer />
    </>
  );
}

export default PaimentConfirmation;
