import { useState, useEffect } from "react";

const FetchReservation = () => {
  const [dataResa, setDataResa] = useState([]);
  const ical2json = require("ical2json");

  useEffect(() => {
    // fetch('https://manava.abricode.fr/rest/ical.php?_x=LJJEcMcYLbAV4qBqKOT_GumF6b9KFr636c_oXIg34waxGTvGidb-PBbc3GeFNI58 ',{
    fetch(
      "https://manava.abricode.fr/rest/ical.php?_x=ohoG1mc7Fk7iuH0CCJxWJROZHowGoGLJwbgehPX7S8ETg5gLmM-6KGGaEZIWf4x_",
      {
        method: "GET",
        mode: "cors",

        headers: {
          Accept: "application/ld+json",
        },
      }
    )
      .then((res) => res.text())
      .then((res) => ical2json.convert(res))
      .then((data) => setDataResa(data.VCALENDAR[0].VEVENT))

      .catch(setDataResa([]));
  }, []);

  return dataResa;
};

export default FetchReservation;
