import React from "react";
function NavBar() {


  return (
    <div>
      <nav className="navbar">
        <div className="nav-container" id="nav-container">
          <div
            className="logo"
            style={{
              backgroundImage: "url(./assets/images/logo.png)",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
          <a href="/" className="nav-logo">
            <span style={{ fontFamily: "    Kaushan Script" }}>
              Betikure Parc Lodge
            </span>
          </a>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
