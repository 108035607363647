import { useState, useEffect } from "react";
import { format, addDays, isSaturday, isFriday } from "date-fns";

const CalendarChildren1 = ({
  showDetailsHandle,
  currentMonth,
  uniteName,
  background,
  bookedDays,
  active,
  id,
  index,
  resetData,
  people,
  peopleMax,
  setmodal,
}) => {
  const [selectedDate, setSelectedDate] = useState([]);

  useEffect(() => {
    if (active !== index) {
      setSelectedDate([]);
    }
  }, [active]);

  function getDatesInRange(startDate, endDate) {
    const date = new Date(startDate.getTime());

    //  Exclude start date
    // date.setDate(date.getDate() + 1);

    const dates = [];

    //  Exclude end date
    while (date <= endDate) {
      // console.log( bookedDays.flat().toString().includes(date.toString()));
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  }

  // selectDate after second click
  useEffect(() => {
    if (selectedDate.length == 2) {
      const datesBeetweenSelectionedDate = getDatesInRange(
        selectedDate[0],
        selectedDate[1]
      );

      setSelectedDate(datesBeetweenSelectionedDate);

      // Check if Date are not Booked on the middle
      if (bookedDays.flat().length > 0) {
        if (
          bookedDays
            .map((e) => datesBeetweenSelectionedDate.toString().includes(e))
            .some((e) => e === true)
        ) {
          setSelectedDate([]);
        } else {
          setSelectedDate(datesBeetweenSelectionedDate);
      
        }
      }
    }
  }, [selectedDate.length]);

  const onDateClickHandle = (day, dayStr, room, id) => {
    
    if (!selectedDate.find((e) => e.getTime() == day.getTime())) {
      setSelectedDate((array) => [...array, new Date(day)]);

      if (selectedDate.length >= 2) {
        setSelectedDate([new Date(day)]);
      }
    } else {
      // setSelectedDate(array => [...array.filter(e =>e.getTime() != day.getTime())])
      setSelectedDate([new Date(day)]);
    }

    showDetailsHandle(day, room);
  };

  const RenderCells = () => {
    let width = window.innerWidth;

    if (width >= 1300) {
      const startDate = currentMonth;
      startDate.setHours(0, 0, 0);
      // const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
      // const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
      const endDate = currentMonth;
      const dateFormat = "d";
      const rows = [];
      let days = [];
      let day = startDate;

      while (day <= endDate) {
        for (let i = 0; i < 32; i++) {
          const cloneDay = day;
          days.push(
            <div
              className={`col cell ${
                bookedDays.flat().find((e) => e.toString() == day.toString())
                  ? "booked"
                  : isFriday(day) || isSaturday(day)
                  ? "weekend"
                  : "weekDay"
              }  ${
                selectedDate.find((e) => e.toString() == day.toString())
                  ? "selected"
                  : ""
              }`}
              key={day}
              onClick={(e) => {
                const dayStr = format(cloneDay, "ccc dd MMM yy");

                onDateClickHandle(cloneDay, dayStr, uniteName, id);
              }}
            ></div>
          );
          day = addDays(day, 1);
        }

        rows.push(
          <div className="row " key={day}>
            {days}
          </div>
        );
        days = [];
      }
      return (
        <div className="body" style={{ backgroundColor: background }}>
          <div className="uniteName" title="Equipements">
            <a onClick={() => setmodal(true)} href={"#" + uniteName}>
        
              <p>{uniteName}</p>
              <i className="fa-regular fa-circle-info"></i>
            </a>
          </div>
          <div className="col cell people">{people}</div>
          <div className="col cell people">{peopleMax}</div>
          {rows}
        </div>
      );
    }
  };
  const MobileRenderCells = () => {
    let width = window.innerWidth;
    if (width <= 450) {
    // const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const startDate = currentMonth;
    startDate.setHours(0, 0, 0);
    // const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = currentMonth;
    const dateFormat = "d";
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 10; i++) {
        const cloneDay = day;
        days.push(
          <div
            className={`col cell ${
              bookedDays.flat().find((e) => e.toString() == day.toString())
                ? "booked"
                : isFriday(day) || isSaturday(day)
                ? "weekend"
                : "weekDay"
            }  ${
              selectedDate.find((e) => e.toString() == day.toString())
                ? "selected"
                : ""
            }`}
            key={day}
            // onClick={(e) => {
            //   const dayStr = format(cloneDay, "ccc dd MMM yy");

            //   onDateClickHandle(cloneDay, dayStr, uniteName, id);
            // }}
          >
            {/* <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span> */}
          </div>
        );
        day = addDays(day, 1);
      }

      rows.push(
        <div className="row " key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return (
      <div className="body" style={{ backgroundColor: background }}>
        <div className="uniteName">
          <a onClick={() => setmodal(true)} href={"#" + uniteName}>
            {uniteName}
            <i className="fa-regular fa-circle-info"></i>
          </a>{" "}
        </div>
        {rows}
      </div>
    );
    }
  };
  const TabletRenderCells = () => {
  
    let width = window.innerWidth;

    if ((width >= 450) & (width <= 1500)) {
      // const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
      const startDate = currentMonth;
      startDate.setHours(0, 0, 0);
      // const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
      const endDate = currentMonth;
      const dateFormat = "d";
      const rows = [];
      let days = [];
      let day = startDate;

      while (day <= endDate) {
        for (let i = 0; i < 14; i++) {
          const cloneDay = day;
          days.push(
            <div
              className={`col cell ${
                bookedDays.flat().find((e) => e.toString() == day.toString())
                  ? "booked"
                  : isFriday(day) || isSaturday(day)
                  ? "weekend"
                  : "weekDay"
              }  ${
                selectedDate.find((e) => e.toString() == day.toString())
                  ? "selected"
                  : ""
              }`}
              key={day}
         /*      onClick={(e) => {
                const dayStr = format(cloneDay, "ccc dd MMM yy");

                onDateClickHandle(cloneDay, dayStr, uniteName, id);
              }} */
            >
              {/* <span className="number">{formattedDate}</span>
            <span className="bg">{formattedDate}</span> */}
            </div>
          );
          day = addDays(day, 1);
        }

        rows.push(
          <div className="row " key={day}>
            {days}
          </div>
        );
        days = [];
      }

      return (
        <div className="body" style={{ backgroundColor: background }}>
          <div className="uniteName">
            <a onClick={() => setmodal(true)} href={"#" + uniteName}>
              {uniteName}
              <i className="fa-regular fa-circle-info"></i>
            </a>{" "}
          </div>
          {rows}
        </div>
      );
    }
  };
  

  return (
    <div className="calendarChildren">
      <div className="desktopRenderDays">
        <RenderCells />
      </div>

      <div className="mobileRenderDays">
        <MobileRenderCells />
      </div>
      <div className="tabletRenderDays">
        <TabletRenderCells />
      </div>
    </div>
  );
};

export default CalendarChildren1;
